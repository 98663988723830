















































































































































































































































































import { HtmlModal, SvgImage } from '@/app/components';
import { computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';

// import { onBeforeRouteLeave } from '@/app/composable/router';
import { useAxios, useQueryParams, useSockets } from '@/app/composable';
import store from '@/app/store';
import { S } from '@/app/utilities';
import { ExecutionAPI, WorkflowAPI } from '../api';
import {
    Configure,
    DisabledBlocksModal,
    GraphView,
    ResultsView,
    WorkflowTriggers,
    TableView,
    TaskConfigurationPanel,
    UpgradeVersionModal,
    ValidationSummary,
    WorkflowMenu,
} from '../components';
import { useVisualisation } from '../composable';
import { useTasks } from '../composable/tasks';
import { useWorkflowDesigner } from '../composable/workflow-designer';
import {
    BlockCategory,
    BlockOutputType,
    BlockType,
    ExecutionStatusWrapper,
    ExecutionStorageLocation,
    ExecutionType,
    WorkflowDesignerViews,
} from '../constants';
import { UpdateWorkflowDTO } from '../dto';
import { Loop, Pipeline, Task } from '../types';
import { WorkflowStatus } from '@/modules/apollo/constants/apollo-pipeline.constants';

export default defineComponent({
    name: 'WorkflowDesigner',
    metaInfo() {
        return {
            title: `Analytics Pipeline Design${(this as any).workflow ? ` for: ${(this as any).workflow.name}` : ''}`,
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        backTo: {
            type: String,
            default: 'workflows',
        },
        backToId: {
            type: String,
            default: null,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        WorkflowMenu,
        OrbitSpinner,
        GraphView,
        TableView,
        ResultsView,
        Configure,
        WorkflowTriggers,
        TaskConfigurationPanel,
        ValidationSummary,
        HtmlModal,
        SvgImage,
        DisabledBlocksModal,
        UpgradeVersionModal,
    },
    setup(props, { root }) {
        const { exec, loading, error } = useAxios(true);
        const { set, get, onChange } = useQueryParams(root, root.$router, 'workflow-designer:edit');
        const viewQueryParam = 'view';

        const disableClickEvent = ref(false);
        const currentPage = ref(get(viewQueryParam, false, WorkflowDesignerViews.GraphView));
        const currentDropdown = ref<any>(null);
        const taskInConfig = ref<Task | null>(null);
        const saving = ref<string | undefined>();
        const deleting = ref<string | undefined>();
        const addingTask = ref<boolean>(false);
        const showTaskConfiguration = ref<boolean>(false);

        const triggersError = ref<string | null>(null);

        const showConfirmFinalise = ref<boolean>(false);

        const taskComposable = useWorkflowDesigner(props.id, root, props.queryParams);
        const { deleteVisualisation } = useVisualisation();
        const isLoading = computed(() => loading.value || taskComposable.loading.value || loadingWorkflow.value);
        const user = computed(() => store.state.auth.user);

        onChange(() => (currentPage.value = get(viewQueryParam, false, WorkflowDesignerViews.GraphView)));

        const {
            workflow,
            pipelines,
            dagLoops,
            loops,
            tasks,
            taskMap,

            refetchWorkflow: refetch,
            deleteTask,
            createTask,
            updateTask,
            queueExecution,

            runningExecution,
            pendingExecutions,
            failedExecutions,
            blockedExecutions,
            otherRunningExecutions,
            validationErrors,
            invalidTaskIds,
            blockedTaskIds,
            finaliseWorkflow,
            unlockWorkflow,
            taskVisualisations,
            visualisations,
            isFinalised,
            isDeprecated,
            canBeReopened,
            isLocked,
            deleteDisabledTasks,
            canUpgradeFrameworkVersion,
            upgradeWorkflow,
            upgradeWorkflowDry,
            onMessage,
            blocksNotSupportedInNewVersion,
            blocksUpgradedInNewVersion,
            showUpgradeConfirmation,
            loading: loadingWorkflow,
            latestExecution,
            newLogMessageArrived,
            processTasks,
        } = taskComposable;

        const { columnsPerTask } = useTasks(taskMap);

        const canEditTrigger = computed(
            () =>
                workflow.value?.createdBy?.id == user.value?.id && workflow.value?.status !== WorkflowStatus.Suspended,
        );
        const name = computed(() => (workflow.value ? workflow.value.name : ''));

        const isOnPremise = computed(() => (workflow.value ? !R.isNil(workflow.value.runner) : false));
        const runnerId = computed(() => (workflow.value && workflow.value.runner ? workflow.value.runner.id : null));

        const errors = computed(() => {
            const listOfErrors = [];
            if (error.value) {
                listOfErrors.push(error.value);
            }
            return listOfErrors.concat(...taskComposable.errors.value);
        });

        const deletedBlocks = computed(() => {
            if (tasks.value) {
                return R.pluck(
                    'displayName',
                    tasks.value.filter((task: Task) => task.block.deletedAt),
                );
            }
            return [];
        });

        const hasDeletedBlock = computed(() => {
            if (deletedBlocks.value) {
                return deletedBlocks.value.length > 0;
            }
            return false;
        });

        const toggleDropdown = (dropdown: string) => {
            currentDropdown.value = dropdown === currentDropdown.value ? null : dropdown;
        };

        const back = () => {
            if (props.backToId) {
                root.$router.push({
                    name: props.backTo,
                    params: { id: props.backToId, queryParams: props.queryParams },
                });
            } else if (props.backTo === 'assets') {
                root.$router.push({ name: 'assets', query: JSON.parse(props.queryParams) });
            } else {
                root.$router.push({ name: 'workflows', query: JSON.parse(props.queryParams) });
            }
        };

        const showSettings = (task: Task | null, forceOpen: boolean = false) => {
            showTaskConfiguration.value = forceOpen || showTaskConfiguration.value || (!taskInConfig.value && !!task);
            taskInConfig.value = task;
        };

        const showSettingsById = (taskId: string | null, forceOpen: boolean = false) => {
            if (R.isNil(taskId) || !taskMap.value.has(taskId)) {
                taskInConfig.value = null;
            } else {
                showSettings(taskMap.value.get(taskId) as Task, forceOpen);
            }
        };

        const taskChange = async (taskId: string | null = null, forceOpen: boolean = false) => {
            await refetch().then(() => {
                showSettingsById(taskId, forceOpen);
            });
        };

        const createSpecificTask = async (taskPayload: {
            displayName: string;
            blockId: string;
            workflowId: string;
            configuration: any;
        }) => {
            addingTask.value = true;
            createTask(taskPayload).then(async (res: any) => {
                if (!R.isNil(res)) {
                    taskMap.value.set(res.data.id, res.data);
                    await processTasks([...taskMap.value.values()]);

                    addingTask.value = false;
                    showSettingsById(res.data.id, true);
                }
            });
        };

        const deleteSpecificTask = async (task: Task) => {
            deleting.value = task.id;
            if (S.has(task.id, taskVisualisations.value)) {
                await deleteVisualisation(taskVisualisations.value[task.id]);
            }
            deleteTask(task)
                .then(async () => {
                    await taskChange();
                    showSettings(null);
                })
                .catch((e: { response: { status: any } }) => {
                    if (e.response && e.response.status === 403) {
                        (root as any).$toastr.e('The analytics pipeline is locked by another user', 'Error');
                    }
                })
                .finally(() => {
                    deleting.value = undefined;
                });
        };

        const runTaskTestRun = async (task: Task) => {
            (root as any).$toastr.s(
                `Submitted request to simulate a run up to task <strong>${S.sanitizeHtml(
                    task.displayName,
                )}</strong> and fetching sample data`,
                'Success',
            );
            await queueExecution(ExecutionType.Test, task);
        };

        const runWorkflowNormalRun = async () => {
            if (!R.isNil(workflow.value)) {
                (root as any).$toastr.s(
                    `Submitted request to run analytics pipeline <strong>${S.sanitizeHtml(
                        workflow.value.name,
                    )}</strong>`,
                    'Success',
                );

                await queueExecution(ExecutionType.Normal);
            }
        };

        const saveWorkflowConfiguration = (configuration: UpdateWorkflowDTO) => {
            exec(WorkflowAPI.update(props.id, configuration))
                .then(async () => {
                    refetch();
                })
                .catch((e) => {
                    if (e.response && e.response?.status === 403 && e.response?.data?.message === 'Locked') {
                        (root as any).$toastr.e('The analytics pipeline is locked by another user', 'Error');
                    } else {
                        (root as any).$toastr.e(
                            'The analytics pipeline configuration update has failed due to an error',
                            'Error',
                        );
                    }
                });
        };

        const updateWorkflowPipelines = (pls: Pipeline[]) => {
            if (JSON.stringify(pipelines.value) !== JSON.stringify(pls)) {
                saveWorkflowConfiguration({
                    ...workflow.value,
                    configuration: {
                        ...workflow.value.configuration,
                        pipelines: pls,
                    },
                });
            }
        };

        const replaceOldTaskWithUpdated = async (updatedTask: any, loopId?: string) => {
            // update the task in task map
            taskMap.value.set(updatedTask.id, updatedTask);

            // fix upstream/downstream task references if appropriate
            taskMap.value.forEach((t: Task) => {
                if (t.id !== updatedTask.id) {
                    // if updated task has this task in it's upstreams then make sure that
                    // updated task is in the task's downstreams and if not remove it
                    if (updatedTask.upstreamTaskIds.includes(t.id)) {
                        if (!t.downstreamTaskIds.includes(updatedTask.id)) t.downstreamTaskIds.push(updatedTask.id);
                    } else t.downstreamTaskIds = t.downstreamTaskIds.filter((id: string) => id !== updatedTask.id);

                    // if updated task has this task in it's downstreams then make sure that
                    // updated task is in the task's upstreams and if not remove it
                    if (updatedTask.downstreamTaskIds.includes(t.id)) {
                        if (!t.upstreamTaskIds.includes(updatedTask.id)) t.upstreamTaskIds.push(updatedTask.id);
                    } else t.upstreamTaskIds = t.upstreamTaskIds.filter((id: string) => id !== updatedTask.id);
                }
            });

            // update presence of updated task in loop
            workflow.value = {
                ...workflow.value,
                configuration: {
                    ...workflow.value.configuration,
                    loops: workflow.value.configuration.loops.map((loop: Loop) => {
                        // if task does not belong in any loop make sure we remove it
                        if (!loopId && loop.tasks.includes(updatedTask.id))
                            loop.tasks = loop.tasks.slice(0, loop.tasks.indexOf(updatedTask.id));
                        // if task belongs to a loop and it's not present then add it
                        else if (loopId === loop.startFor && !loop.tasks.includes(updatedTask.id))
                            loop.tasks.push(updatedTask.id);
                        // make sure lastTask has the last task in the list
                        loop.lastTask = loop.tasks[loop.tasks.length - 1];
                        return loop;
                    }),
                },
            };

            // trigger the rest of the chain of actions to update the tasks
            await processTasks([...taskMap.value.values()]);
        };

        const saveTask = (task: Task, pls: Pipeline[]) => {
            const ogTask = taskMap.value.get(task.id);
            saving.value = task.id;
            updateTask(task)
                .then(async (updatedTask: any) => {
                    // we refetch for a for loop change since this changes the
                    // pipeline configuration and we should refetch it to make
                    // sure we have the right structure
                    if (
                        updatedTask.block.category === BlockCategory.Control &&
                        updatedTask.block.type === BlockType.Loop
                    )
                        await refetch();
                    else {
                        updateWorkflowPipelines(pls);
                        runningExecution.value = null;

                        // since workflow is not refetched we need to make sure
                        // the task is updated in place correctly with all it's connections
                        await replaceOldTaskWithUpdated(updatedTask, task.loopId);
                        if (
                            !blockedTaskIds.value.includes(updatedTask.id) &&
                            !R.isNil(ogTask) &&
                            !R.equals(ogTask.configuration, updatedTask.configuration) &&
                            updatedTask.block.category !== BlockCategory.Output &&
                            updatedTask.block.output.type === BlockOutputType.Dynamic &&
                            !(
                                updatedTask.block.category === BlockCategory.MachineLearning &&
                                updatedTask.block.type === 'evaluate'
                            )
                        ) {
                            await queueExecution(ExecutionType.Dry, updatedTask);
                        }
                    }
                    showSettingsById(updatedTask.id);
                })
                .catch((e: any) => {
                    const message = R.pathOr(
                        'Something went wrong while saving this task',
                        ['response', 'data', 'message'],
                        e,
                    );
                    if (e.response && e.response.status === 403 && e.response.data.message === 'Locked') {
                        (root as any).$toastr.e('Analytics pipeline is locked by another user', 'Error');
                    } else {
                        (root as any).$toastr.e(message, 'Error');
                    }
                })
                .finally(() => {
                    saving.value = undefined;
                });
        };

        const handleDropdowns = (dropdown: string) => {
            toggleDropdown(dropdown);
        };

        const toggleClickEvent = (disable: boolean) => {
            disableClickEvent.value = disable;
        };

        const confirmFinaliseWorkflow = () => {
            showConfirmFinalise.value = true;
        };

        const performFinaliseWorkflow = () => {
            showConfirmFinalise.value = false;
            finaliseWorkflow()
                .then(() => {
                    if (errors.value.length === 0) {
                        (root as any).$toastr.s(`Analytics pipeline has been successfully finalised!`, 'Success');
                    } else {
                        (root as any).$toastr.e(errors.value[0], 'Error');
                    }
                })
                .catch((err: { response: { data: { message: string } } }) => {
                    if (err.response.data.message === 'Locked') {
                        (root as any).$toastr.e(
                            `Finalise of the analytics pipeline has failed because pipeline is locked by another user.`,
                            'Error',
                        );
                    } else {
                        (root as any).$toastr.e(err.response.data.message, 'Error');
                    }
                });
        };

        const visualisationChange = () => {
            refetch();
        };

        const retrievedVisualisations = computed(() => {
            if (visualisations.value) {
                return visualisations.value.map((visualisation: any) => {
                    return {
                        id: visualisation.id,
                        title: visualisation.title,
                        subtitle: visualisation.subtitle,
                        type: visualisation.type,
                        workflowId: props.id,
                        taskId: visualisation.taskId,
                        configuration: visualisation.configuration,
                        assetId: visualisation.assetId,
                        retrieval: visualisation.retrieval,
                    };
                });
            }
            return [];
        });

        const cancelExecution = (uid: string) => {
            exec(ExecutionAPI.cancel(uid, props.id))
                .then(() => {
                    (root as any).$toastr.s(
                        'The cancellation request has been successfully submitted. Please note that the operation may take some time to finish, and the execution could be completed before the cancellation is processed.',
                        'Success',
                    );
                })
                .catch(() => {
                    (root as any).$toastr.e('Execution has failed to be cancelled', 'Error');
                });
        };

        onUnmounted(async () => {
            await unlockPipeline();
            store.dispatch.pipelineDesigner.clearPipelineDependents();
        });

        const unlockPipeline = async () => {
            await exec(WorkflowAPI.release(props.id as string)).catch(() => null);
        };

        const {
            subscribe,
            unsubscribe,
            WebSocketsEvents,
            joinSocketRoom,
            leaveSocketRoom,
            WebSocketsRoomTypes,
        } = useSockets();

        onMounted(() => {
            window.addEventListener('beforeunload', unlockPipeline);
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
            joinSocketRoom(WebSocketsRoomTypes.Workflow, props.id);
        });
        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.id);
        });

        const closeTaskConfiguration = () => {
            showTaskConfiguration.value = false;
        };

        const performDeleteDisabledTasks = async () => {
            deleteDisabledTasks()
                .then(async () => {
                    refetch();
                })
                .catch(() => {
                    (root as any).$toastr.s('Removing deprecated tasks has failed due to an error', 'Error');
                });
        };

        // Check if workflow is currently running
        watch(
            () => workflow.value,
            (newWorkflow: any) => {
                if (!R.isNil(newWorkflow)) {
                    if (
                        newWorkflow.executions.length > 0 &&
                        !ExecutionStatusWrapper.finishedStatuses().includes(newWorkflow.executions[0].status)
                    ) {
                        runningExecution.value = {
                            executionId: newWorkflow.executions[0].id,
                            type: newWorkflow.executions[0].type,
                            status: newWorkflow.executions[0].status,
                        };
                    }
                }
            },
        );

        watch(
            () => [currentPage.value, workflow.value],
            (curr) => {
                const [currentView, currWorkflow] = curr;
                if (!currWorkflow) return;
                if (
                    // if on-premise, redirect to graph view when requesting results view
                    (isOnPremise.value && currentView === WorkflowDesignerViews.ResultsView) ||
                    // if storage of dry/test runs is local, redirect to graph view when requesting table view
                    (workflow.value.configuration?.location === ExecutionStorageLocation.Local &&
                        currentView === WorkflowDesignerViews.TableView)
                ) {
                    set(viewQueryParam, WorkflowDesignerViews.GraphView);
                } else {
                    set(viewQueryParam, currentView, WorkflowDesignerViews.GraphView);
                }
            },
            { immediate: true },
        );

        store.dispatch.pipelineDesigner.setPipelineId(props.id);

        return {
            tasks,
            taskMap,
            isLoading,
            errors,
            workflow,
            name,
            currentPage,
            toggleDropdown,
            currentDropdown,
            back,
            showSettings,
            taskInConfig,
            createSpecificTask,
            taskChange,
            deleteSpecificTask,
            saveTask,
            runWorkflowNormalRun,
            columnsPerTask,
            error,
            triggersError,
            handleDropdowns,
            saveWorkflowConfiguration,
            disableClickEvent,
            toggleClickEvent,
            runTaskTestRun,
            runningExecution,
            pendingExecutions,
            failedExecutions,
            blockedExecutions,
            otherRunningExecutions,
            validationErrors,
            showSettingsById,
            invalidTaskIds,
            blockedTaskIds,
            confirmFinaliseWorkflow,
            isFinalised,
            isDeprecated,
            showConfirmFinalise,
            performFinaliseWorkflow,
            pipelines,
            dagLoops,
            isOnPremise,
            runnerId,
            visualisationChange,
            retrievedVisualisations,
            saving,
            deleting,
            addingTask,
            unlockWorkflow,
            canBeReopened,
            isLocked,
            canEditTrigger,
            showTaskConfiguration,
            closeTaskConfiguration,
            hasDeletedBlock,
            deletedBlocks,
            performDeleteDisabledTasks,
            canUpgradeFrameworkVersion,
            upgradeWorkflow,
            upgradeWorkflowDry,
            blocksNotSupportedInNewVersion,
            blocksUpgradedInNewVersion,
            showUpgradeConfirmation,
            loops,
            latestExecution,
            WorkflowDesignerViews,
            cancelExecution,
            newLogMessageArrived,
        };
    },
});
