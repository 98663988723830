














































































































































































































































import { ConfirmButton, Toggle } from '@/app/components';
import { S } from '@/app/utilities';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import SimpleBar from 'simplebar-vue';
import { ValidationObserver } from 'vee-validate';
import { useTaskConfiguration } from '../../composable/task-configuration';
import { BlockType } from '../../constants/block-type.constants';
import { Loop, Pipeline, Task } from '../../types';
import TaskConfigurationHeader from './TaskConfigurationHeader.vue';
import TaskDataframesConfiguration from './TaskDataframesConfiguration.vue';
import TaskParameterConfiguration from './TaskParameterConfiguration.vue';
import TaskPipelinesConfiguration from './TaskPipelinesConfiguration.vue';
import { ExclamationIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'TaskConfiguration',
    props: {
        workflowId: { type: String, required: true },
        task: {
            type: Object,
            required: false,
        },
        tasks: {
            type: Map as PropType<Map<string, Task>>,
            default: new Map<string, Task>(),
        },
        columnsPerTask: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        loops: {
            type: Array as PropType<Loop[]>,
            default: () => [],
        },

        isOnPremise: {
            type: Boolean,
            default: false,
        },
        runnerId: {
            type: String,
            default: null,
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        saving: {
            type: Boolean,
            default: false,
        },
        deleting: {
            type: Boolean,
            default: false,
        },
        disableChange: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        isFinalised: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ConfirmButton,
        TaskConfigurationHeader,
        TaskDataframesConfiguration,
        TaskParameterConfiguration,
        ValidationObserver,
        SimpleBar,
        TaskPipelinesConfiguration,
        Toggle,
        ExclamationIcon,
    },
    setup(props, { emit }) {
        const parametersRef = ref<any>();
        const forceUpdate = ref<Date>(new Date());
        const pipelinesClone = ref<Pipeline[]>(R.clone(props.pipelines) as Pipeline[]);
        const pipelineInEdit = ref<boolean>(false);

        const nameIsValid = ref<boolean>(true);

        const taskConfig: Ref<Task> = computed(() => R.clone(props.task) as Task);
        const tasks: Ref<Map<string, Task>> = computed(() => props.tasks);
        const loops: Ref<Loop[]> = computed(() => props.loops);

        const {
            getTask,
            blockCategory,
            blockType,
            availableDataframes,
            dataframeParameters,
            parameters,
            dataframeParametersConfig,
            parametersConfig,
            description,
            canBeInPipeline,
            allMachineLearningDataframes,
            availablePipeline,
            availableMachineLearningDataframes,
            availableVariables,
            currentLoopId,
            canBeInForLoopId,
            savedInLoopId,
            assetStructureForTask,
            currentLoops,
            previousBlockIsLoopBlock,
            selectedAsset,
        } = useTaskConfiguration(taskConfig, tasks, pipelinesClone, loops);
        const inForLoop: Ref<boolean> = computed({
            get: () => !!currentLoopId.value,
            set: (addToLoop: boolean) => {
                if (addToLoop) {
                    currentLoopId.value = canBeInForLoopId.value;
                } else {
                    currentLoopId.value = null;
                }
            },
        });
        const showParameters = ref<boolean>(dataframeParameters.value.length === 0);
        const displayName = ref<string>(taskConfig.value.displayName);

        const configuration = computed(() => {
            let config = {
                ...taskConfig.value,
                displayName: displayName.value,
                configuration: {
                    ...dataframeParametersConfig.value,
                    ...parametersConfig.value,
                },
            };
            // when the model is trained, an 'assetIds' field is added to the configuration of the task
            // we manually add it here, because this field does not exist in the default configuration of the block
            if (props.task?.configuration.assetIds && blockType.value === BlockType.Train) {
                config.configuration.assetIds = props.task?.configuration.assetIds;
            }
            return config;
        });

        const isTaskRunning = computed(
            () =>
                props.task &&
                !R.isNil(props.runningExecution) &&
                !R.isNil(props.runningExecution.task) &&
                props.runningExecution.task.id === props.task.id,
        );

        const isAnyTaskRunning = computed(() => !R.isNil(props.runningExecution));

        const waitingForDataframeCalculation = computed(() =>
            Object.keys(dataframeParametersConfig.value).reduce((acc: boolean, dataframeKey: string) => {
                const taskId = dataframeParametersConfig.value[dataframeKey].task;

                return (
                    acc ||
                    !props.columnsPerTask ||
                    !S.has(taskId, props.columnsPerTask) ||
                    R.isNil(props.columnsPerTask[taskId])
                );
            }, false),
        );

        const hasChanges = computed(
            (): boolean =>
                JSON.stringify(props.task) !== JSON.stringify(configuration.value) ||
                JSON.stringify(props.pipelines) !== JSON.stringify(pipelinesClone.value) ||
                savedInLoopId.value !== currentLoopId.value,
        );

        const canSave = computed(() => {
            if (props.isFinalised || !parametersRef.value) return false;
            for (let f = 0; f < Object.keys(parametersRef.value.fields).length; f++) {
                const fieldKey = Object.keys(parametersRef.value.fields)[f];
                const field = parametersRef.value.fields[fieldKey];
                if (Object.keys(field.failedRules).filter((rule: string) => rule !== 'required').length > 0) {
                    return false;
                }
            }
            return (
                hasChanges.value && !waitingForDataframeCalculation.value && !pipelineInEdit.value && nameIsValid.value
            );
        });

        const parametersDefinition = computed(() =>
            parameters.value.reduce((acc: any, p: any) => {
                acc[p.name] = p;
                return acc;
            }, {}),
        );

        const showSettings = (task: Task) => {
            emit('show-settings', task);
        };

        const deleteTask = () => {
            emit('delete', props.task);
        };

        const trimConfiguration = (config: any): any => {
            if (R.is(Object, config)) {
                return Object.keys(config).reduce((acc: any, key: string) => {
                    const c = config[key];
                    if (R.is(Array, c)) {
                        acc[key] = c.map((subConf: any) => trimConfiguration(subConf));
                    } else {
                        acc[key] = trimConfiguration(c);
                    }
                    return acc;
                }, {});
            }
            if (R.is(Array, config)) {
                return (config as any[]).map((c: any) => {
                    if (R.is(Array, c)) {
                        return c.map((subConf: any) => trimConfiguration(subConf));
                    }
                    return trimConfiguration(c);
                });
            }
            if (R.is(Boolean, config)) {
                return config;
            }
            return R.is(String, config) ? R.trim(config) : config;
        };

        const save = () => {
            let task = trimConfiguration(configuration.value);

            //  task already in a loop and changes to a different loop by selecting different loop upstream task
            if (currentLoopId.value && canBeInForLoopId.value && currentLoopId.value !== canBeInForLoopId.value)
                currentLoopId.value = canBeInForLoopId.value;

            task = { ...task, loopId: currentLoopId.value };
            emit('save', task, pipelinesClone.value);
        };

        const parameterChange = () => {
            if (parametersRef.value) {
                parametersRef.value.validate();
            }
            forceUpdate.value = new Date();
        };

        const testRun = () => {
            emit('test-run', props.task);
        };

        const isValid = (task: Task) => !props.invalidTaskIds.includes(task.id);

        const nameValidatyChange = (valid: boolean) => {
            nameIsValid.value = valid;
        };

        watch(
            () => props.pipelines,
            (newPipelines: any) => {
                pipelinesClone.value = R.clone(newPipelines);
            },
        );

        watch(
            () => parametersRef.value,
            () => {
                parameterChange();
            },
        );

        watch(
            () => hasChanges.value,
            (changes: boolean) => {
                emit('set-check-changes', changes);
            },
        );

        return {
            R,
            canSave,
            blockType,
            taskConfig,
            parameters,
            hasChanges,
            description,
            forceUpdate,
            displayName,
            parametersRef,
            configuration,
            isTaskRunning,
            blockCategory,
            pipelinesClone,
            pipelineInEdit,
            showParameters,
            canBeInPipeline,
            parametersConfig,
            isAnyTaskRunning,
            availablePipeline,
            availableVariables,
            dataframeParameters,
            availableDataframes,
            parametersDefinition,
            dataframeParametersConfig,
            allMachineLearningDataframes,
            waitingForDataframeCalculation,
            availableMachineLearningDataframes,
            save,
            testRun,
            isValid,
            getTask,
            deleteTask,
            showSettings,
            parameterChange,
            nameValidatyChange,
            inForLoop,
            canBeInForLoopId,
            assetStructureForTask,
            currentLoops,
            previousBlockIsLoopBlock,
            selectedAsset,
        };
    },
});
